<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="bg-white p-1 full col-md-10">
          <div class="grid grid-cols-1 gap-1 lg:grid-cols-4 lg:gap-4">
            <div class="flex">
              <div class="flex">
                <span class="icon-pos"><i
                    class="fa fa-calendar"></i></span><h5 class="w-24 " style="padding-top: 10px">
                {{ $t('paymentCard.startDate') }}</h5>
              </div>

              <div>
                <date-picker
                    valueType="format"

                    v-model="form.start_date"
                    class="w-100 payout-xmx"
                    prefix-class="xmx"
                    :placeholder="$t(form.label)"
                />
              </div>
            </div>
            <div class="flex">
              <div class="flex">
                <span class="icon-pos"><i
                    class="fa fa-calendar"></i></span>
                <h5 class="w-24" style="padding-top: 10px">{{ $t('paymentCard.endDate') }}</h5>
              </div>

              <div>
                <date-picker
                    valueType="format"
                    v-model="form.end_date"
                    class="w-100 payout-xmx"
                    prefix-class="xmx"
                    :placeholder="$t(form.label)"
                />
              </div>
            </div>
            <div class="flex">
              <p class="" style="padding-top: 10px">{{ $t('paymentCard.includeCreditedInvoices') }}</p>
              <div style="padding-top: 10px" class="ml-1">
                <b-form-checkbox
                    v-model="form.include_credited"
                    class="text-white"
                >

                </b-form-checkbox>
              </div>
            </div>
            <div class="flex justify-center lg:justify-end">
              <b-button v-on:click="applyPaidFilter"
                        size="sm"
                        class="h-10 w-48"
                        variant="outline-primary"
                        v-text="$t('paymentCard.apply')"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="flex justify-end">
            <b-button v-on:click="exportPaidInvoice"
                      size="sm"
                      class="p-1"
                      variant="outline-primary"
                      v-text="$t('paymentCard.exportExcel')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container bg-white mt-1">
      <div class="scrol-area table-responsive">
        <table class="table b-table table-hover">
          <thead class="bg-white">
          <tr>
            <th
                v-for="(item, i) in fields"
                :key="i"
                class="text-center whitespace-nowrap p-2 md:p-0 bg-white"
            >
              <div
                  class="flex justify-between gap-5 items-center w-full"
              >
                        <span class="text-xs text-gray-400">{{
                            $t(item.label)
                          }}</span>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <invoice-tbl v-for="(data,i) in items.results" :key="i" :invoice="data"/>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import invoiceTbl from "@core/components/golfrelated/paidInvoice/InvoiceTbl"
import download from "downloadjs";

export default {
  name: "paidGolfInvoices",
  components: {
    DatePicker, invoiceTbl
  },
  data: function () {
    return {
      form: {
        start_date: '',
        end_date: '',
        include_credited: false,
      },
      items: {
        results: []
      },
      fields: [
        {
          label: 'paymentCard.name'
        },
        {
          label: 'paymentCard.golfId'
        },
        {
          label: 'paymentCard.fortnoxInvoiceNumber'
        },
        {
          label: 'paymentCard.gitInvoiceNumber'
        },
        {
          label: 'paymentCard.finalPayDate'
        },
        {
          label: 'paymentCard.isCredit'
        },
        {
          label: 'paymentCard.hasBeenCredited'
        },
      ]
    }
  },
  methods: {
    getPaidInvoices: function () {
      this.$useJwt.getPaidInvoices({URL: ``, method: "get"}).then(resp => {
        this.items.results = resp.data.data
      }).catch(err => {

      })
    },
    exportPaidInvoice: function () {
      if (this.form.end_date === '' || this.form.start_date === '') {
        this.popupMsg(
            this.$t("Message.Failed"),
           this.$t('paymentCard.ExportError'),
            "AlertTriangleIcon",
            "danger"
        );
        return false
      }
      this.$useJwt.getPaidInvoices({URL: ``, method: "post"}, this.form).then(resp => {
        this.downloadURI(resp.data.data)
      }).catch(err => {

      })
    },
    applyPaidFilter: function () {
      let params = `?limit=20`
      if (this.form.start_date) {
        params += `&start_date=${this.form.start_date}`
      }
      if (this.form.end_date) {
        params += `&end_date=${this.form.end_date}`
      }
      let include_credited = this.form.include_credited === true ? 1 : 0
      params += `&include_credited=${include_credited}`
      this.$useJwt.getPaidInvoices({URL: params, method: "get"}).then(resp => {
        this.items.results = resp.data.data
      }).catch(err => {

      })
    },
    downloadURI: function (uri) {
      let downloadLink = document.createElement("a");
      downloadLink.href = uri;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

    }
  },
  mounted() {
    this.getPaidInvoices()
  }
}
</script>

<style scoped>
.icon-pos {
  padding-top: 9px;
  margin-right: 5px;
}
</style>