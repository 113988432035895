import { render, staticRenderFns } from "./InvoiceTbl.vue?vue&type=template&id=fa87fba4&scoped=true&"
import script from "./InvoiceTbl.vue?vue&type=script&lang=js&"
export * from "./InvoiceTbl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa87fba4",
  null
  
)

export default component.exports