<template>
  <tr>
    <td>
      <span>{{ invoice.name }}</span>
    </td>
    <td>
      <span>{{ invoice.golf_id }}</span>
    </td>
    <td>
      <span>{{ invoice.accounting_invoice_number }}</span>
    </td>
    <td>
      <span>{{ invoice.git_invoice_number }}</span>
    </td>
    <td>
      <span>{{ invoice.final_pay_date }}</span>
    </td>
    <td class="text-center">
      <span v-if="invoice.is_credit"><svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
<path
    d="M12.5 17L19.875 9.625C20.1042 9.39583 20.3958 9.28125 20.75 9.28125C21.1042 9.28125 21.3958 9.39583 21.625 9.625C21.8542 9.85417 21.9688 10.1458 21.9688 10.5C21.9688 10.8542 21.8542 11.1458 21.625 11.375L13.375 19.625C13.125 19.875 12.8333 20 12.5 20C12.1667 20 11.875 19.875 11.625 19.625L8.375 16.375C8.14583 16.1458 8.03125 15.8542 8.03125 15.5C8.03125 15.1458 8.14583 14.8542 8.375 14.625C8.60417 14.3958 8.89583 14.2812 9.25 14.2812C9.60417 14.2812 9.89583 14.3958 10.125 14.625L12.5 17Z"
    fill="#4ECB71"/>
</svg>
</span>
      <span v-else>
         <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M0.125 0.75L3.04167 7L0.125 13.25H3.04167L4.5 10.125L5.95833 13.25H8.875L5.95833 7L8.875 0.75H5.95833L4.5 3.875L3.04167 0.75H0.125Z"
    fill="#F24E1E"/>
</svg>

</span>
    </td>
    <td class="text-center">
      <span v-if="invoice.has_been_credited"><svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                  xmlns="http://www.w3.org/2000/svg">
<path
    d="M12.5 17L19.875 9.625C20.1042 9.39583 20.3958 9.28125 20.75 9.28125C21.1042 9.28125 21.3958 9.39583 21.625 9.625C21.8542 9.85417 21.9688 10.1458 21.9688 10.5C21.9688 10.8542 21.8542 11.1458 21.625 11.375L13.375 19.625C13.125 19.875 12.8333 20 12.5 20C12.1667 20 11.875 19.875 11.625 19.625L8.375 16.375C8.14583 16.1458 8.03125 15.8542 8.03125 15.5C8.03125 15.1458 8.14583 14.8542 8.375 14.625C8.60417 14.3958 8.89583 14.2812 9.25 14.2812C9.60417 14.2812 9.89583 14.3958 10.125 14.625L12.5 17Z"
    fill="#4ECB71"/>
</svg>
</span>
      <span v-else>
         <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M0.125 0.75L3.04167 7L0.125 13.25H3.04167L4.5 10.125L5.95833 13.25H8.875L5.95833 7L8.875 0.75H5.95833L4.5 3.875L3.04167 0.75H0.125Z"
    fill="#F24E1E"/>
</svg>

</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "InvoiceTbl",
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>